// :height="tableHeight" :row-height="rowHeight" use-virtual ref="table"

// import tableHeight from "@/common/tableHeightMixins";
// mixins: [tableHeight],

const mixin = {

    data() {
        const h = document.documentElement.clientHeight - 122;
        return {
            tableHeight: h,
            rowHeight: 32
        }
    },
    mounted() {
        window.addEventListener("resize", () => {
            this.tableHeight = document.documentElement.clientHeight - 122;
        });
    },
    activated() {
        if (this.$refs.table) {
            this.$refs.table.doLayout();
        }
    },

}
export default mixin;